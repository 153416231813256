<template>
	<div class="mt-4">
		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto mb-5 rounded-xl" max-width="1200" />
			</template>
			<template v-else>
				<div class="text-center my-2" key="topPagination" v-if="uploads.length">
					<v-pagination :value="page" :length="pages" total-visible="10" @input="goToPage" />
				</div>
				<FeedUpload v-for="(upload, index) in uploads" :key="index" :upload="upload" />
				<div class="text-center my-2" key="bottomPagination" v-if="uploads.length">
					<v-pagination :value="page" :length="pages" total-visible="10" @input="goToPage" />
				</div>
			</template>
		</v-fade-transition>

		<h3 v-if="!loading && uploads.length == 0 && setupInfo.accountType.student.value" class="m-4">
			{{ $t('newsfeed.emptyFeedMessage') }}
		</h3>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('newsfeed.uploads'),
		}
	},
	components: {
		FeedUpload: () => import('@/components/feed/FeedUpload.vue'),
	},
	data() {
		return {
			page: 1,
			perPage: 10,
			pages: 1,
		}
	},
	computed: {
		...mapGetters({
			uploads: 'feed/uploads',
			loading: 'feed/loading',
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
		this.perPage = this.$route.query.perPage ? parseInt(this.$route.query.perPage) : 10
		this.updateList(this.page, this.perPage)
	},
	methods: {
		goToPage(page) {
			this.updateList(page, this.perPage)
		},
		updateList(toPage, perPage) {
			if (this.setupInfo.accountType.student.value) {
				this.$router.replace({ name: this.$route.name, query: { page: toPage, perPage } })
				this.fetchUploads({ courseID: 0, page: toPage, perPage }).then(({ pages }) => {
					this.page = toPage
					this.perPage = perPage
					this.pages = pages
				})
			}
		},
		...mapActions('feed', ['fetchUploads']),
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
