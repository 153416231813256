import { render, staticRenderFns } from "./NewsfeedUploads.vue?vue&type=template&id=27adc26f&scoped=true&"
import script from "./NewsfeedUploads.vue?vue&type=script&lang=js&"
export * from "./NewsfeedUploads.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27adc26f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VFadeTransition,VPagination,VSkeletonLoader})
